import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/core'
import Button from 'components/common/atoms/Button'
import NewTypography from 'components/common/atoms/NewTypography'

type ErrorProps = {
  code?: 404 | 500
  customText?: string
  customButton?: ReactNode
}

const containerStyle = () => css`
  display: flex;
  justify-content: center;

  .error_container {
    text-align: center;
    margin: 100px 16px;
    max-width: 400px;

    @media screen and (max-width: 767px) {
      margin: 60px 16px;
    }

    img {
      width: 100%;
      margin-bottom: 24px;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    h1,
    p {
      margin-bottom: 16px;
    }

    .error_cta {
      a {
        height: 40px;
        padding: 12px 16px;
        margin: 0;
        @media screen and (min-width: 768px) {
          height: 50px;
        }
      }
    }
  }
`

const codeDetail = {
  500: {
    title: 'Terjadi Kesalahan Server',
    text: 'Mohon maaf, terdapat kesalahan saat menyambung ke server. Silakan coba lagi nanti.',
  },
  404: {
    title: 'Oops! Halaman tidak ditemukan',
    text: 'Mohon maaf, halaman yang Kamu cari tidak dapat ditemukan. Silakan periksa URL-nya.',
  },
}

const ErrorContainer: FC<ErrorProps> = ({ code = 500, customText, customButton }) => {
  const { title, text } = codeDetail[code]

  return (
    <div css={containerStyle}>
      <div className="error_container">
        <img src={`/next-assets/images/error/${code}.svg`} alt="404" />
        <NewTypography tagName="h1" variant="heading" level="1" variantPC="title" levelPC="3">
          {title}
        </NewTypography>
        <NewTypography tagName="p" variant="paragraph" level="2">
          {customText || text}
        </NewTypography>

        <div className="error_cta">
          {customButton || (
            <Button type="button" tagName="link" variant="primary" href="/" isFullWidth>
              Kembali ke beranda
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ErrorContainer
